<template>
<div class="footer-section">
    <div class="container">
        <b-row>
            <b-col cols="2" xl="1" class="container-logo">
                <img v-if="ChangeImagen"  class="footer-logo" v-bind:src="imgContainer"/>

            </b-col>
            <b-col cols="10" xl="11" class="footer-container">
                <div class="footer-container1">
                    <div class="left" v-if="showFooter">
                      <ul>
                        <li><a href="#header">Inicio</a></li>
                        <li><a href="#machinery">Maquinaria & Accesorios</a></li>
                      </ul>
                    </div>
                    <div class="right" v-if="showFooter">
                        <ul>
                          <li><a href="#personal">Personal</a></li>
                          <li><a href="#training">Videos de capacitación</a></li>
                        </ul>
                    </div>
                </div>
                <div class="footer-container2">
                    <div class="number">
                        <ul>
                          <li><img src="@/assets/img/CelularF.png" alt=""><a href="https://wa.me/917655102"  target="_blank"> +51 917 655 102</a></li>
                    </ul>
                    </div>
                    <div class="mail">
                        <ul>
                        <li><img src="@/assets/img/CorreoF.png" alt="">
                        <a href="mailto:servicios@igmservice.net">servicios@igmservice.net</a></li>
                        </ul>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</div>
</template>
<script>
import imgFooter1 from '@/assets/img/LogoIGMF.png'
import imgFooter2 from '@/assets/img/LogoIGMF2.png'
export default {
    data(){
        return{
            img1:imgFooter1,
            img2:imgFooter2,
            imgContainer:imgFooter2,
            showFooter: true
        }
    },
    methods:{
        ChangeImagen(){
            if(window.innerWidth>1099){
                this.imgContainer=this.img2
            }else{
                this.imgContainer=this.img1
            }
        },
        showFooterFunc() {
        if(this.$router.history.current.name == 'Certificado') this.showFooter = false;
        else this.showFooter = true;
      }
    },
    mounted() {
        this.ChangeImagen();
    },
    watch: {
        $route() {
        this.showFooterFunc()
        }
    },
    created() {
    this.showFooterFunc()
  }
}
</script>