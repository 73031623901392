<template>
<div>
<b-navbar toggleable="lg" id="navbar">
  <b-container>
    <b-navbar-brand href="#">
      <img  v-if="changeImagen" v-bind:src="imagenN" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" >
      <span class="item-dos hidden1" href="https://wa.me/917655102"  target="_blank">
        <img src="@/assets/img/iconoNav.png" alt="">
        +51 917 655 102</span>
      <img v-bind:src="barrasN" alt="">
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <div class="container-contact">
          <b-nav-item class="item-dos hidden2" href="https://wa.me/917655102"  target="_blank">
            <img src="@/assets/img/iconoNav.png" alt="">
            +51 917 655 102
            </b-nav-item>
          <b-nav-item href="mailto:servicios@igmservice.net"> 
            <img src="@/assets/img/iconoNav2.png" alt="">
             servicios@igmservice.net
          </b-nav-item>
        </div>
        <div class="container-item" v-if="showNavbar">
          <b-nav-item href="#header">Inicio</b-nav-item>
          <b-nav-item href="#machinery">Maquinaria & Accesorios</b-nav-item>
          <b-nav-item href="#personal">Personal</b-nav-item>
          <b-nav-item href="#training">Videos de capacitación</b-nav-item>
        </div>
      </b-navbar-nav>
    </b-collapse>
  </b-container>
</b-navbar>
</div>
</template>
<script>
import logoNavar1 from '@/assets/img/LogoIGMN1.png'
import logoNavar2 from '@/assets/img/LogoIGMN2.png'
import barras from '@/assets/img/barrasNav.png'
export default {
  data(){
    return{
      barrasN:barras,
      logoN1:logoNavar1,
      logoN2:logoNavar2,
      imagenN:logoNavar1,
      showNavbar: true
    }
  },
  watch: {
    $route() {
      this.showNavbarFunc()
    }
  },
  mounted() {
    this.changeImagen();
  },
  methods:{
    changeImagen(){
     let navbar = document.querySelector("#navbar");
     let _this = this;

      window.onscroll = () => {
        if(window.scrollY > 20){
          navbar.classList.add("header-scrolled");
          console.log(_this)
          _this.imagenN=_this.logoN2
        }else{
            navbar.classList.remove("header-scrolled");
            _this.imagenN=_this.logoN1
        }
      }
    },
    showNavbarFunc() {
      if(this.$router.history.current.name == 'Certificado') this.showNavbar = false;
        else this.showNavbar = true;
      }
  },
  created() {
    this.showNavbarFunc()
  }
}
</script>