<template>
   <div class="contact container" :class="{'activador':active}">
      <b-row class="contact-row">
          <b-col cols="6" lg="4" class="left">
             <h6><span>Para más información</span>CONTÁCTANOS</h6>
          </b-col>
          <b-col cols="12" lg="4" class="arrow" >
             <img src="@/assets/img/triangulo.png" v-on:click="active = !active" />
          </b-col>
          <b-col  cols="6" lg="4" class="right">
             <div class="div-second">
                <img src="@/assets/img/iconoNav2.png" >
                <p class="second">servicios@igmservice.net</p>
              </div>
              <div class="div-first">
                 <img src="@/assets/img/iconoNav.png">
                 <p class="first" href="https://wa.me/917655102"  target="_blank">+51 917 655 102</p>
              </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    data(){
        return{
            active:true,
            showClassScroll:false
        }
    },
    mounted(){
        this.classScroll();
    },
    methods:{
        changeArrow() {
            console.log(123)
        },
    }
}
</script>